import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, Observable, ReplaySubject, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TimeRangeEnum } from '../../models/enums/timeRange.enum';
import * as qs from 'qs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { APIResponse, Contract, Data, UserProductWalletPerf } from '../../models';
import { UserProductWalletPerfDetail } from '../../models/user-product-wallet-perf-detail.model';
import { CoinProductWalletCoinDetails } from '../../models/coinProductWalletCoinDetails.model';
import { StorageService } from '../storage/storage.service';
import { UserProductWalletCoinDetails } from '../../models/userProductWalletCoinDetails.model';
import { UserService } from '../user/user.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private activeWallet$ = new ReplaySubject<Data<Contract> | undefined>(0);
  constructor(private httpClient: HttpClient, private storage: StorageService, private userService: UserService) {
    this.userService.onlineUser$.subscribe(user => {
      if (!user) {
        this.activeWallet$.next(undefined);
        this.storage.set('walletId', undefined);
      }
    });
  }

  public loadProduct(walletId: number) {
    return lastValueFrom(
      this.httpClient
        .get<APIResponse<Data<Contract>>>(`${environment.baseUrl}/clients/getCompleteContract/${walletId}`)
        .pipe(
          take(1),
          map((response) => response.data)
        )
    ).then((userWallet) => {
      this.activeWallet$.next(userWallet);
      return;
    });
  }

  get(): Observable<Data<Contract> | undefined> {
    return this.activeWallet$.asObservable();
  }

  public async getCurrentWallet(): Promise<number | undefined> {
    return await this.storage.get('walletId');
  }

  public async setCurrentWallet(walletId: number): Promise<void> {
    this.storage.set('walletId', walletId);
  }

  getAccountNumber(): Observable<string | undefined> {
    return this.get().pipe(
      map((contract: Data<Contract> | undefined) => {        
        return contract?.attributes.accountNumber;
      }),
      untilDestroyed(this)
    )
  }

  reload() {
    return lastValueFrom(this.activeWallet$.pipe(take(1))).then((wallet?: Data<Contract>) => {
      if (!wallet) {
        this.activeWallet$.next(undefined);
        return;
      }
      return this.loadProduct(wallet.id);
    });
  }

  getHistoryLines(timeRange: TimeRangeEnum | null, walletId: number): Observable<Data<UserProductWalletPerf>[]> {
    const minDate: Date = new Date();
    switch (timeRange) {
      case TimeRangeEnum.OVER_TIME:
        minDate.setFullYear(0);
        break;
      case null:
        minDate.setFullYear(0);
        break;
      case TimeRangeEnum.YEAR_TO_DATE:
        minDate.setMonth(0);
        minDate.setDate(1);
        break;
      case TimeRangeEnum.ONE_YEAR:
        minDate.setMonth(minDate.getMonth() - 12);
        break;
      case TimeRangeEnum.SIX_MONTH:
        minDate.setMonth(minDate.getMonth() - 6);
        break;
      case TimeRangeEnum.THREE_MONTH:
        minDate.setMonth(minDate.getMonth() - 3);
        break;
      case TimeRangeEnum.ONE_MONTH:
        minDate.setMonth(minDate.getMonth() - 1);
        break;
    }
    const query: string = qs.stringify(
      {
        filters: {
          date: {
            $gt: minDate,
          },
        },
        fields: ['id', 'date', 'totalEuroValue', 'performance'],
        sort: ['date:asc'],
        pagination: {
          pageSize: 365 * 5,
        },
      },
      { encode: false }
    );
    return this.httpClient
      .get<APIResponse<Data<UserProductWalletPerf>[]>>(
        `${environment.baseUrl}/clients/getContractPerformances/${walletId}?${query}`
      )
      .pipe(
        map((userProductWalletPerfs: APIResponse<Data<UserProductWalletPerf>[]>): Data<UserProductWalletPerf>[] => {
          return userProductWalletPerfs.data;
        })
      );
  }

  getPerformanceTable(productId: number): Observable<Data<UserProductWalletPerfDetail>[]> {
    return this.httpClient
      .get<APIResponse<Data<UserProductWalletPerfDetail>[]>>(`${environment.baseUrl}/products/${productId}/performances/detail`)
      .pipe(
        take(1),
        map((userProductWalletPerfsDetail: APIResponse<Data<UserProductWalletPerfDetail>[]>): Data<UserProductWalletPerfDetail>[] => {
          return userProductWalletPerfsDetail.data;
        })
      )
  }

  getPerfDetailSelectedValue(periodTypeSelected: TimeRangeEnum | null, performanceTable: Data<UserProductWalletPerfDetail>[]): Data<UserProductWalletPerfDetail> | undefined {
    return performanceTable.find(
      (data) => data.attributes.periodType === periodTypeSelected
    );
  }
  
  getUserProductWalletCoinsDetails(): Observable<UserProductWalletCoinDetails | undefined> {
    return this.get().pipe(
      map((contract: Data<Contract> | undefined) => {
        return contract?.attributes?.latestUserProductWalletPerf?.data?.attributes?.dataTokenDetails;
      })
    );
  }

  getCoinDetailsByUserProductWallet(userProductWallet: Data<Contract>, coinId: number): CoinProductWalletCoinDetails | undefined {
    const dataTokenDetails: UserProductWalletCoinDetails | undefined = userProductWallet.attributes.latestUserProductWalletPerf.data.attributes.dataTokenDetails;
    return dataTokenDetails!.coin.find(coin => coin.coinDetail.id === coinId);
  }
}
