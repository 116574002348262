import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import CordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import {Drivers} from "@ionic/storage";

@Injectable({
  providedIn: 'root'
})

export class StorageService {
  private storage: Storage | null = null;
  private available: Promise<void>;
  constructor(private storageApi: Storage) {
    this.available = new Promise<void>(
      (resolve, reject) => {
        this.init().then(() => {
          resolve()
        });
      }
    )

  }

  async init() {
    const origStore = new Storage();
    await origStore.create();
    const newStore = new Storage({
      name: 'mlcSqlDb',
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
    });
    await newStore.defineDriver(CordovaSQLiteDriver);
    await newStore.create();
    if (await origStore.length() > 0) {
      await origStore.forEach((value, key, index) => {
        newStore.set(key, value);
      });
      this.storage = newStore;
      await origStore.clear();
    } else {
      this.storage = newStore;
    }
  }

  public set(key: string, value: any) {
    this.available.then(() => {
      this.storage?.remove(key);
      this.storage?.set(key, value);
    })

  }

  public async get(key: string) {
   return this.available.then(() => {
     return this.storage?.get(key)
   });
  }
}
