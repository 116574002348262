import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ContractService } from '../../core/services/wallet/wallet.service';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Contract, Data, TimeRangeEnum, TimeRangeList, UserProductWalletPerf } from '@mlc/types';
import * as echarts from 'echarts';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { TranslocoService } from '@ngneat/transloco';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { UserProductWalletPerfDetail } from 'src/app/core/models/user-product-wallet-perf-detail.model';

@UntilDestroy()
@Component({
  selector: 'app-evolution-card',
  templateUrl: './evolution-card.component.html',
  styleUrls: ['./evolution-card.component.scss'],
})
export class EvolutionCardComponent implements OnInit, OnDestroy {

  @ViewChild('walletChart', { static: true }) chartContainer!: ElementRef;
  public chart: any;
  public timeRangeList: TimeRangeEnum[] = TimeRangeList;
  public emptyChart: boolean = false;
  public busy: boolean = true;
  public timeRangeSelectionControl = new FormControl<TimeRangeEnum>(this.timeRangeList[0]);
  public parentWidth!: number | undefined;
  public parentHeight!: number | undefined;
  public chartPrimaryColor: string = "#C84A1C";
  public chartSecondaryColor: string = "#848484";
  public baseOpacity: number = 0.6
  public totalValueList!: number[];
  public perfDetail!: Data<UserProductWalletPerfDetail> | undefined;
  public isModalVisible: boolean = false;
  public performanceTableTimeRange!: Data<UserProductWalletPerfDetail>[];

  constructor(private contractService: ContractService, private translocoService: TranslocoService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.timeRangeSelectionControl.valueChanges
    .pipe(
      switchMap(() => {
        return this.contractService.get();
      }),
      switchMap((contract: Data<Contract> | undefined) => {
        this.perfDetail = undefined;
        this.busy = true;
        if (!contract) {
          return of();
        }
        const historyLines: Observable<Data<UserProductWalletPerf>[]> = this.contractService.getHistoryLines(this.timeRangeSelectionControl.value, contract.id);
        const performanceTable: Observable<Data<UserProductWalletPerfDetail>[]> = this.contractService.getPerformanceTable(contract.id);
        return forkJoin([historyLines, performanceTable]);
      }),
      untilDestroyed(this)
    )
    .subscribe({
      next: ([lines, performanceTable]: [Data<UserProductWalletPerf>[], Data<UserProductWalletPerfDetail>[]]) => {
        this.busy = false;
        this.emptyChart = true;
        if (performanceTable && lines && lines.length) {
          this.perfDetail = this.contractService.getPerfDetailSelectedValue(this.timeRangeSelectionControl.value, performanceTable);
          this.performanceTableTimeRange = performanceTable;
          this.createChart(lines);
          this.emptyChart = false;
        }
      }
    });
    this.timeRangeSelectionControl.setValue(TimeRangeList[0]);
  }

  @HostListener('window:resize')
  onResize() {
    this.chartResize();
  }

  @HostListener('document:touchstart', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.chartContainer && !this.chartContainer.nativeElement.contains(event.target as Node)) {
      this.onOutsideClick();
    }
  }

  ngOnDestroy(): void {
    this.chart?.dispose();
  }

  createChart(lines: Data<UserProductWalletPerf>[]) {
    this.chart?.dispose();
    const data: string[][] = lines.map((line) => [line.attributes.date, line.attributes.totalEuroValue]);
    const dateList: string[] = data.map(item => item[0]);
    this.totalValueList = data.map(item => +item[1]);
    this.parentWidth = this.chartContainer?.nativeElement!.parentElement.clientWidth;
    this.parentHeight = this.chartContainer?.nativeElement!.parentElement.clientHeight;

    this.chart = echarts.init(this.chartContainer.nativeElement, 'dark', { width: this.parentWidth, height: this.parentHeight});

    this.chart.setOption({
      backgroundColor: 'transparent',
      animate: true,
      tooltip: {
        axisPointer: {
          type: 'none'
        },
        trigger: 'axis',
        formatter: (params: any) => {
          let tooltip = `${format(parseISO(params[0].name), 'd MMM yyyy', { locale: fr })}<br/>`;
          tooltip += `${params[0].seriesName}: ${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(params[0].data)}`;
          return tooltip;
        },
      },
      grid: {
        top: '10px',
        bottom: "10px",
        left: "10px",
        right: "20px",
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dateList,
        axisLabel: {
          formatter: (value: string) => {
            return format(parseISO(value), 'dd MMM', { locale: fr });
          },
          color: this.chartSecondaryColor,
          opacity: this.baseOpacity / 3
        },
        axisTick: {
          lineStyle: {
            color: this.chartSecondaryColor,
            opacity: this.baseOpacity / 3
          }
        },
        axisLine: {
          lineStyle: {
              color: this.chartSecondaryColor,
              opacity: 0
          },
        },
      },
      yAxis: {
        type: 'value',
        position: 'right',
        splitLine: {
          lineStyle: {
              color: this.chartSecondaryColor,
              opacity: this.baseOpacity / 5
          },
        },
        axisLabel: {
          formatter: (value: number) => {
            return `${value / 1000}k€`;
          },
          color: this.chartSecondaryColor,
          opacity: 0.2
        },
        min: function (value: any) {
          return (value.min - (value.min % 1000));
        },
      },
      series: [
        {
          name: this.translocoService.translate('HOME.VALUE_CHART'),
          id: 0,
          animation: true,
          data: this.totalValueList,
          type: 'line',
          smooth: false,
          dot: false,
          itemStyle: {
            color: this.chartPrimaryColor,
          },
          lineStyle: {
            width: 4,
          },
        },
      ],
    })
    this.chart.on('highlight', async (params: {batch? : [{dataIndex: number}]}) =>  {
      const dataIndex = params.batch ? params.batch[0]?.dataIndex : null;
      if (!dataIndex) return
      this.updateLineColors(dataIndex, this.totalValueList);
      this.chart.setOption({
        xAxis: {
          show: true
        },
        yAxis: {
          show: true
        }
      });
      await Haptics.impact({ style: ImpactStyle.Light }).catch();
    });
  }

  private updateLineColors(index: number, totalValueList: number[]) {
    const selectedSeries = totalValueList.slice(0, index + 1);
    this.chart.setOption({
        xAxis: {
          axisLabel: {
            opacity: this.baseOpacity
          },
        },
        yAxis: {
          axisLabel: {
              opacity: this.baseOpacity
          },
          splitLine: {
            lineStyle: {
              color: this.chartSecondaryColor,
              opacity: this.baseOpacity / 2
            },
          },
        },
      series: [
        {
          name: "",
          data: totalValueList,
          type: 'line',
          dot: false,
          smooth: false,
          legendHoverLink: false,
          animation: false,
          zlevel: 0,
          itemStyle: {
            color: this.chartSecondaryColor,
            opacity: this.baseOpacity / 2
          },
          lineStyle: {
            width: 4,
          },
        },
        {
          name: this.translocoService.translate('HOME.VALUE_CHART'),
          id: 0,
          data: selectedSeries,
          zlevel: 1,
          type: 'line',
          smooth: false,
          animation: true,
          itemStyle: {
            color: this.chartPrimaryColor,
          },
          lineStyle: {
            width: 4,
          },
        }
      ]
    });
  }

  private onOutsideClick() {
    if (this.totalValueList) {
      this.updateLineColors(this.totalValueList.length, this.totalValueList);
    }
    this.chart.dispatchAction({
      type: 'downplay'
    });
    this.chart.dispatchAction({
      type: 'hideTip'
    });
    this.chart.setOption({
      xAxis: {
        axisLabel: {
          opacity: this.baseOpacity / 3
        },
      },
      yAxis: {
        axisLabel: {
          opacity: this.baseOpacity / 3
        },
        splitLine: {
          lineStyle: {
            color: this.chartSecondaryColor,
            opacity: this.baseOpacity / 5
          },
        },
      },
    });
  }

  public chartResize() {
    this.cdr.detectChanges();
    this.parentWidth = this.chartContainer?.nativeElement!.parentElement.clientWidth;
    this.parentHeight = this.chartContainer?.nativeElement!.parentElement.clientHeight;
    this.chart?.resize({
      width: this.parentWidth,
      height: this.parentHeight
    });
  };

  toggleModal() {
    this.isModalVisible = !this.isModalVisible;
  }

}
