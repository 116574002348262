<div class="px-3 py-2 md:cursor-pointer ripple-parent rounded-rectangle ion-activatable" [ngClass]="{ 'pointer-events-none': numberOfWallet < 2 }">
  <ion-ripple-effect *ngIf="numberOfWallet > 1"></ion-ripple-effect>
  <ion-label *ngIf="numberOfWallet > 1" color="medium" class="flex-grow text-sm">{{ 'WALLET.SELECT_LABEL' | transloco }}</ion-label>
  <ion-button color="light" [class]="numberOfWallet > 1 ? '': 'text-left'" class="flex items-center " (click)="toggleModal()">
    <img *ngIf="selectedWalletLogo" [src]="selectedWalletLogo" class="w-10 h-10 mr-2 rounded-full" />
    <ion-label class="truncate !text-xl font-bold">{{ selectedWalletName }}</ion-label>
    <ion-icon *ngIf="numberOfWallet > 1" name="chevron-expand-outline" class="text-xl ml-auto"></ion-icon>
  </ion-button>
</div>

<app-sheet-modal
  [title]="'WALLET.SELECT.TITLE' | transloco"
  [(isModalOpen)]="isModalOpen"
>
  <p class="text-gray-500 m-4 whitespace-pre-wrap">{{ 'WALLET.SELECT.DESCRIPTION' | transloco }}</p>
  <ng-container *ngIf="user?.productWallets">
    <app-base-list [listTitle]="'WALLET.SELECT.PERSONNAL' | transloco">
      <app-item-list
        (click)="selectedWalletOption(wallet)"
        *ngFor="let wallet of user?.productWallets"
        [subtitle]="wallet.product.name"
        [iconPicture]="wallet.product.productLogo?.url"
        [clickable]="true"
        [sizeLarge]="true"
      >
      </app-item-list>
    </app-base-list>
  </ng-container>

  <ng-container *ngIf="user?.userCompany">
    <app-base-list [listTitle]="'WALLET.SELECT.COMPANY' | transloco">
      <app-item-list
        (click)="selectedWalletOption(companyWallet)"
        *ngFor="let companyWallet of this.user?.userCompany?.productWallets"
        [subtitle]="companyWallet.product.name"
        [iconPicture]="companyWallet.product.productLogo?.url"
        [clickable]="true"
        [sizeLarge]="true"
      >
      </app-item-list>
    </app-base-list>
  </ng-container>
</app-sheet-modal>
